/* stylelint-disable -- Developing */
.dc-logo {
    padding: 30px 0;
    width: 200px;

    @include breakpoint(small only) {
        margin: 0 auto;
        display: block;
        padding: 15px 0;
    }

    &.logo-large {
        width: 310px;
        max-width: 80%;
    }
}

.fancy-fade {
    position: fixed;
    width: 100vw;
    height: 50vh;
    background: linear-gradient(170deg, transparent 70%, rgba($main-accent, 0.1));
    z-index: 81;
    bottom: 0;
    pointer-events: none;
}

.heading {
    background-attachment: fixed;
    background-size: cover;
    height: 280px;
    border-radius: 5px;

    h1 {
        width: 800px;
        max-width: 80%;
        margin: 0;
    }

    @include breakpoint(small only) {
        height: auto;

        h1 {
            max-width: 90%;
        }
    }
}

.heading.short {
    position: relative;

    .bg-img-container {
        position: relative;
        display: flex;
        height: 100%;
        padding: 20px 25px;
    }

    .dc-menu {
        position: absolute;
        top: 0;
        left: 0;
    }

    h1 {
        position: relative;
        z-index: 10;
    }
}

.separator-cell {
    position: relative;
    background: #fff;
    z-index: 10;

    .lt {
        position: absolute;
        height: 10px;
        width: 20px;
        top: 0;
        left: -20px;
        border-radius: 0 10px 0 0;
        box-shadow: 10px 0 0 0 #fff;
    }

    .lb {
        position: absolute;
        height: 10px;
        width: 20px;
        bottom: 0;
        left: -20px;
        border-radius: 0 0 10px 0;
        box-shadow: 10px 0 0 0 #fff;
    }

    .rt {
        position: absolute;
        height: 10px;
        width: 20px;
        top: 0;
        right: -20px;
        border-radius: 10px 0 0 0;
        box-shadow: -10px 0 0 0 #fff;
    }

    .rb {
        position: absolute;
        height: 10px;
        width: 20px;
        bottom: 0;
        right: -20px;
        border-radius: 0 0 0 10px;
        box-shadow: -10px 0 0 0 #fff;
    }

    @include breakpoint(small only) {
        display: none;
    }
}

.calendar {
    margin: 0;
    z-index: 10;

    .ui-datepicker {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: transparent;
        th {
            padding: 6.8px;
        }
    }

    .ui-datepicker table {
        margin: 0 0 4px;
    }

    .highlighted-over a,
    .highlighted-open a {
        background-image: none !important;
        color: white !important;
        font-weight: bold !important;
        font-size: 12pt;
    }

    .highlighted-over a {
        background-color: #acadae !important;
    }

    .highlighted-open a {
        background-color: #95a5f3 !important;
    }

    thead,
    tbody,
    tfoot {
        border: 0; 
    }


    .ui-widget-header {
        background: transparent;
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    html .ui-button.ui-state-disabled:hover,
        html .ui-button.ui-state-disabled:active {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 4px;
    }

    .ui-state-highlight,
    .ui-widget-content .ui-state-highlight,
    .ui-widget-header .ui-state-highlight {
        border: 1px solid #fcefa1;
        background: #fffada;
    }

    tbody tr:nth-child(even) {
        background: #fff;
    }

    .ui-datepicker td {
        padding: 0;
    }

    .ui-datepicker .ui-datepicker-prev,
    .ui-datepicker .ui-datepicker-prev-hover,
    .ui-datepicker .ui-datepicker-next,
    .ui-datepicker .ui-datepicker-next-hover {
        top: 3px;
    }


    @include breakpoint(small only) {
        display: none;
    }
}

.dc-events .calendar {
    display: none;
    margin: 0 0 50px 0;

    @include breakpoint(small only) {
        display: block;
    }
}

.menu {
    font-family: $font-sans-serif;
    padding: 25px 0;

    li {
        padding-right: 25px;
        margin-bottom: 0;
    }

    a {
        color: black;
        padding: 2px 0;
    }

    .active > a {
        font-weight: 700;
        background: transparent;
        color: black;
        padding: 2px 0;
    }

    @include breakpoint(small only) {
        overflow-x: auto;
        white-space: nowrap;
        padding: 15px 0;
        display: block;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent; /* make scrollbar transparent */
        }

        li {
            display: inline-block;
        }
    }
}

header.grid-container {
    position: relative;
}

.languages {
    position: absolute;
    display: block;
    top: 25px;
    right: 15px;
    width: 50px;
    border-radius: 5px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 100;
    transition: all 0.1s ease;

    &.front-page {
        top: 35px;
    }

    span {
        position: relative;
        display: block;
        padding-top: 5px;
        font-size: 26px;
        line-height: 30px;
        z-index: 10;
    }

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        height: 0;
        overflow: hidden;
        list-style: none;
        text-align: center;
        z-index: 10;
    }

    ul li {
        margin: 0;
        padding: 0;
    }

    ul li a {
        display: block;
        padding: 10px 15px;
        color: black;
    }

    &:hover {
        box-shadow: 0 15px 30px 0 rgba(11, 14, 34, 0.15),
            0 2px 15px 0 rgba(129, 176, 189, 0.3), 0 1px 2px 0 rgba(4, 69, 93, 0.25);

        ul {
            height: auto;
        }

        .header-image {
            opacity: 1;
        }
    }

    .header-image {
        border-radius: 5px;
        opacity: 0;
        transition: all 0.1s ease;
    }

    .background-gradient {
        height: 200px;
    }

    .background-pattern {
        background-size: 200px;
    }

    @include breakpoint(small only) {
        top: 70px;

        &.front-page {
            top: 85px;
        }
    }
}

.dc-title {
    min-height: 120px;
    height: 100%;
    position: relative;

    h1 {
        margin: 0;
    }

    .cell {
        padding: 10px 25px;
        z-index: 2;
    }
}

@include breakpoint(small only) {
    .home .dc-title {
        min-height: 180px;
    }

    .dc-title {
        height: auto;
    }
}

.dc-menu {
    position: absolute;
    z-index: 2;
    padding: 0 25px;

    &.inner {
        position: relative;
        display: inline-block;

        @include breakpoint(small only) {
            padding: 0;
            width: 100%;
        }
    }

    @include breakpoint(small only) {
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 30%;
            height: 100%;
            right: 0;
            top: 0;
            max-width: 200px;
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1)
                );
            pointer-events: none;
        }
    }
}

.dc-thumbnail {
    display: block;
    width: 100%;
    height: 300px;
    color: white;
    position: relative;
    text-decoration: none;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background-position: center center;
    transition: all 100ms ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 70%);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: $main-accent;
        opacity: 0.4;
    }

    &:hover {
        color: white;
    }

    .dc-description {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 25px;
        z-index: 3;
    }

    h3,
    h4,
    h5,
    p {
        margin: 0;
    }

    p {
        margin-top: 10px;
    }
}

.dc-category {
    font-size: 14px;
    padding: 4px;
    background: $grey;
    color: white;
    text-transform: uppercase;
    margin: 0 4px;
    border-radius: 2px;
    text-decoration: none;

    &:hover {
        color: white;
        opacity: 0.7;
    }
}

header > a {
    @include breakpoint(small only) {
        width: 100%;
    }
}

.dc-article {
    display: block;
    color: black;
    padding: 20px 25px;
    background: #f9f9f9;
    border-radius: 0 0 5px 5px;

    &.fullborder {
        border-radius: 5px;

        h3 {
            margin: 0;
        }
    }

    a h3 {
        color: black;
        margin: 0;

        &:hover {
            color: $color-hover;
        }
    }

    p {
        margin: 10px 0 0 0;
        font-size: 18px;
    }
}

.dc-date {
    padding: 10px 0;
    margin: 0;
}

.grey {
    color: $grey;
}

.dc-content {
    p {
        margin: 30px 0;
    }

    .featured {
        display: block;
        left: 0;
        right: 0;
        position: relative;
        margin: 30px auto;
        max-height: 600px;
    }
}

.core {
    padding-top: 70px;

    @include breakpoint(small only) {
        padding-top: 30px;
    }

    & .cell > h2,
    .cell > h4 {
        padding-bottom: 30px;
        height: 50px;
        vertical-align: middle;
        display: table-cell;
    }
}

.dc-events {
    @include breakpoint(small only) {
        margin-top: 10px;
    }
}

.tournament,
.dc-latest-news {
    background: #f9f9f9;
    cursor: pointer;
    margin-bottom: 75px;
    position: relative;
    padding: 20px;
    border-radius: 5px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 $grey;
        background: transparent;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &:hover::after {
        opacity: 0.3;
        transition: opacity 200ms ease-in-out;
    }

    &.active {
        background-image: url('../assets/images/usr.svg');

        .background-pattern {
            background-size: 171px;
            left: 0;
            top: 0;
        }

        .background-gradient {
            height: 100%;
            border-radius: 5px;
            left: 0;
            top: 0;
        }
    }

    &.dot::before {
        content: '';
        width: 14px;
        height: 14px;
        display: none;
        border-radius: 100%;
        position: absolute;
        left: -6px;
        top: -6px;
        z-index: 2;
    }

    &.active::before {
        display: block;
        background: $main-accent;
    }

    &.inactive::before {
        display: block;
        background: $grey;
    }

    &.closed::before {
        display: block;
        background: #eddbac;
    }

    h5,
    p {
        margin: 0;
        z-index: 2;
        color: black;
        position: relative;
    }

    .dc-thumbnail {
        height: 150px;

        h5,
        p {
            color: white;
        }
    }

    span {
        position: absolute;
        top: -30px;
        right: 0;
        color: $grey;
    }
}

.dc-latest-news {
    padding: 0;
    border: 2px solid $main-accent;

    a {
        padding: 20px;
        display: block;
        position: relative;
        z-index: 2;
    }

    .dc-thumbnail {
        border-radius: 5px;
    }
}

.dc-line {
    display: block;
    width: 100%;
    height: 1px;
    background: $grey;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 2px;
}

footer {
    padding: 50px 0 30px 0;
    z-index: 10;
    position: relative;
    background: white;
}

.footer-social {
    font-size: 0;
    padding-right: 8px;

    i {
        font-size: 22px;
    }

    &:hover {
        filter: saturate(1);
        transition: filter 200ms;
    }

    @include breakpoint(small only) {
        max-width: 28px;
        max-height: 28px;
        padding-right: 3px;
    }
}

.app-img {
    display: block;
    width: 100%;
    max-width: 140px;
}

.app-img img {
    display: block;
    width: 100%;
    margin-bottom: 3px;
}

.end {
    position: absolute;
    left: 0;
    bottom: 0;
    background: black;
    width: 100%;
    height: 6px;
    z-index: 11;

    .background-pattern {
        background-size: 130px;
        height: 6px;
    }

    .background-gradient {
        height: 6px;
    }
}

.dc-content {
    margin: 30px 0 70px 0;
}

.header-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.background-pattern {
    width: 100%;
    display: block;
    height: 100%;
    background: url(../assets/images/usr.svg);
    background-size: 400px;
    box-shadow: inset 0 0 0 1px black;
    opacity: 0.08;
    position: absolute;
    z-index: 2;
}

.background-gradient {
    width: 100%;
    display: block;
    height: 400px;
    z-index: 1;
    position: absolute;
    animation: dcLinear 60s linear infinite;
    background: linear-gradient(
        90deg,
        #6bb8ff,
        #9fa7ff,
        #ffb6ef,
        #9fa7ff,
        #6bb8ff
        );
    backface-visibility: hidden;
    background-size: 5000px;

    &::after {
        content: '';
        background: linear-gradient(45deg, transparent, #fff);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

.hd-header {
    margin-top: -1px;
    padding-left: 25px;
    padding-right: 0;
}

.hd-social-parent {
    .hd-social {
        width: 37px;

        img {
            position: absolute;
            transform: translateY(-25%);
        }
    }

    @include breakpoint(medium down) {
        height: 14px;
    }
}

.heading.inner {
    min-height: 120px;
    height: auto;

    .background-gradient,
    .inner .background-pattern {
        min-height: 120px;
        height: auto;
    }
}

@-webkit-keyframes dcLinear {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -10000px 0;
    }
}

@-moz-keyframes dcLinear {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -10000px 0;
    }
}

@-o-keyframes dcLinear {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -10000px 0;
    }
}

@keyframes dcLinear {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -10000px 0;
    }
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    z-index: 999;
    overflow: hidden;
}

.contains {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    animation: hey 400ms linear;
}

@keyframes hey {
    0% {
        transform: translateY(-120vh);
    }

    90% {
        transform: translateY(-15%);
    }

    100% {
        transform: translateY(-50%);
    }
}

.contains .preloader-img {
    display: inline-block;
    width: 32px;
    max-width: 100%;
    animation: heyyo 2s ease-in-out infinite;
    position: relative;
}

.first {
    animation-delay: 600ms !important;
}

.second {
    animation-delay: 800ms !important;
}

.third {
    animation-delay: 1s !important;
}

@keyframes heyyo {
    79% {
        transform: translateX(-120vw);
        left: calc(120vw);
    }

    80% {
        transform: none;
        left: 0;
    }
}

.dc-info {
    margin-top: 40px;

    div {
        color: #f9f9f9;
    }

    h3,
    h3 {
        margin-bottom: 10px;
        color: black;
        font-size: 16px;
    }

    p {
        margin: 0;
        color: $grey;
    }

    .medium-4 {
        margin-bottom: 20px;
    }
}

.inner h2 {
    font-size: 28px;
    margin: 0;

    @include breakpoint(small only) {
        font-size: 24px;
    }
}

.tab.show-for-small-only {
    float: left;
    padding: 10px;
    width: 50%;
    margin-bottom: 12px;
    background: #f9f9f9;
    border-bottom: 2px solid $grey;
    cursor: pointer;

    &:nth-child(even) {
        box-shadow: inset 50px 0 20px -50px rgba(0, 0, 0, 0.38);
        box-shadow: inset 50px 0 20px -50px rgba(0, 0, 0, 0.38);
        box-shadow: inset 50px 0 20px -50px rgba(0, 0, 0, 0.38);
    }

    &:nth-child(odd) {
        box-shadow: inset -50px 0 20px -50px rgba(0, 0, 0, 0.38);
        box-shadow: inset -50px 0 20px -50px rgba(0, 0, 0, 0.38);
        box-shadow: inset -50px 0 20px -50px rgba(0, 0, 0, 0.38);
    }

    h2 {
        height: auto;
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
}

.tab.show-for-small-only.active {
    //-webkit-box-shadow: inset 2px 19px 0 -15px rgba(149,165,243,1);
    //-moz-box-shadow: inset 2px 19px 0 -15px rgba(149,165,243,1);
    //box-shadow: inset 2px 19px 0 -15px rgba(149,165,243,1);
    background: white;
    border-bottom: 2px solid;
    box-shadow: none;
    color: $main-accent;
}

.popup-bg {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $main-accent;
    opacity: 0.65;

    &::after {
        //content: "";
        //position: fixed;
        //left: 0;
        //top: 0;
        //display: block;
        //background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        //height: 100%;
        //width: 100%;
    }
}

.popup {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 21;
    width: 100%;
    left: 0;
    margin: 0 auto;
    right: 0;
}

.popup .content {
    background: white;
    padding: 20px;
    width: 100%;
}

.dc-more-circle {
    font-size: 14px;
    color: white;
    position: absolute;
    background: black;
    width: 19px;
    height: 19px;
    text-align: center;
    border-radius: 100%;
    line-height: 19px;
    cursor: pointer;
    display: inline-block;
    top: 20px;
    margin-left: 7px;

    .questionmark {
        display: block;
    }
}

.title .moreinfo {
    position: absolute;
    left: -85px;
    right: 0;
    top: 29px;
    width: 300px;
    height: auto;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: left;
    padding: 10px;
    z-index: 5;
    border-radius: 5px;
    max-width: 30vw;

    h6 {
        margin: 0;
    }

    span {
        font-weight: normal;
        font-family: $font-sans-serif-secondary;
        display: block;

        &::after {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            float: left;
            top: 6px;
            position: relative;
            margin-right: 10px;
        }
    }

    .st-first {
        color: $main-accent;

        &::after {
            background-color: $main-accent;
        }
    }

    .st-second {
        color: #eddbac;

        &::after {
            background-color: #eddbac;
        }
    }

    .st-third {
        color: $grey;

        &::after {
            background-color: $grey;
        }
    }

    .button {
        width: 100%;
        background: $main-accent;
        color: black;
        margin-top: 15px;
        margin-bottom: 0;
        padding: 2px 0;
        cursor: pointer;
    }
}

.title:hover .moreinfo {
    display: block !important;

    &.viewed {
        display: none !important;
    }
}

.title .moreinfo.view {
    display: block !important;
}

.donations {
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    span {
        padding: 10px 15px;
        position: relative;
        z-index: 10;
        color: black;
        display: block;
    }
}

main {
    min-height: 30vh;
}

.scrollmagic-pin-spacer {
    z-index: 80;

    @include breakpoint(small only) {
        display: none !important;
    }
}

.title {
    position: relative;
    padding-top: 12px;
    padding-bottom: 35px !important;
}

.regulation {
    position: fixed;
    z-index: 20;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    color: black;

    .dc-gotopost {
        width: 30%;
        min-width: 200px;
        float: right;
        display: block;
        border: 1px;
        color: black;
        font-size: 22px;
        font-family: $font-sans-serif-secondary;
        font-weight: bold;
        text-align: right;
        padding: 20px 0;
    }

    h3 {
        text-align: center;
    }

    p {
        margin: 0;
    }

    .dc-regulation-content {
        background: white;
        border-radius: 5px;
        overflow: auto;
        max-height: 80vh;
        padding: 30px 30px 45px 30px;

        @include breakpoint(small only) {
            padding: 0 15px 20px 15px;
            max-height: 70vh;
        }

        h3 {
            padding: 35px;
            padding-bottom: 0;
        }

        h5 {
            text-align: center;
        }
    }
}

.pagecontent {
    margin-top: 70px;
}

article.tease {
    position: relative;
    cursor: pointer;

    > a {
        display: block;
        position: relative;
        z-index: 2;
    }

    iframe {
        border-radius: 5px;
        display: block;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 $grey;
        background: transparent;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        z-index: 1;
    }

    &:hover::after {
        opacity: 0.3;
        transition: opacity 200ms ease-in-out;
    }
}

article {
    margin-bottom: 70px;

    .dc-content {
        margin: 0;

        p:nth-child(1) {
            margin-top: 20px;
        }
    }

    p {
        font-family: $font-sans-serif-secondary;
    }

    @include breakpoint(small only) {
        margin-bottom: 50px;
    }
}

.soon {
    position: absolute;
    right: unset;
    left: 0;
}

.dc-partner img {
    max-width: 100%;
    filter: saturate(0);
    max-height: 160px;
    padding: 0 20px;

    &:hover {
        filter: saturate(1);
        transition: filter 200ms;
    }
}

.dc-langs a {
    color: white;
    padding: 5px 15px;
    background: $grey;
    border-radius: 5px;
    position: relative;
    top: 15px;
}

.error404 main {
    font-size: 48px;
    font-weight: bold;
}

@media only screen and (min-width: 1024px) and (max-width: 1920px) and (max-height: 850px) {
    .core {
        padding-top: 50px;
    }

    .dc-thumbnail {
        height: 250px;
    }

    .dc-logo.logo-large {
        width: 280px;
    }
}

.button {
    color: #000;
    padding: 10px 25px;
    border-radius: 5px;
    font-family: $font-sans-serif-secondary;
    font-weight: bold;
    background: $main-accent;
    border: 2px solid $main-accent;
}

.button.btn-dark {
    background: $grey;
    border: 2px solid $grey;
}

.button.btn-outlined {
    border: 2px solid $grey;
    background: none;

    &:hover {
        color: $grey;
    }
}

.button.btn-active {
    background: $main-accent;
    border: 2px solid #aab7f3;
}

.date {
    color: $grey;
}

.files {
    max-width: 100%;
    padding: 20px;
    border: solid 2px $grey;
    border-radius: 5px;
    height: 155px;
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 20px;

    img {
        max-height: 100%;
    }
}

@media (min-width: 660px) and (max-width: 814px) {
    .ui-datepicker-title {
        font-size: 0.8em;
    }
    .calendar .ui-datepicker th {
        padding: 8px 1px;
        font-size: 0.9em;
    }
}