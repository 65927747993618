/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 30px;

  @include breakpoint(small only) {
    font-size: 27px;
  }
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}
