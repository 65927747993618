// * ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$fa-font-path: '../../webfonts' !default;
$font-sans-serif: 'Rubik', sans-serif !default;
$font-sans-serif-secondary: 'Lato', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 1px,
  // With0 some css from foundation does NOT work
  medium: 660px,
  large: 1025px,
  // With 1024px the viewpoint with width 1024px was broken in much places
  xlarge: 1600px,
  full: 1920px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$grey: #acadae;
$main-accent: #95a5f3;
$color-dark-grey: #333;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
